import React from 'react';

import Helmet from "react-helmet"
import { withPrefix, Link } from "gatsby"

import Axios from 'axios';

const queryString = require('query-string');

class Request extends React.Component {

    state = {
        loaded: false,
        quote: {}
    }

    async componentDidMount() {

        const queryparams = queryString.parse(this.props.location.search);
        const partner = queryparams.partner;
        
        let brand; 
        let category; 
        const channel = this.props.channel; 
        switch(partner){
            case 'palram': 
                brand = 'palram';
                category = "PALRAM";
                break;
            default:
                brand = channel;
                category = channel.toUpperCase();
                break;
        }
        
        const qUid = queryparams.uid;
        let uid; 
        if(qUid){
            uid = qUid;
        } else {
            const init = await Axios({
                url: '/api/survey/init',
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    country: this.props.country,
                    language: this.props.language === 'de_DE' ? 'de' : this.props.language, //why?
                    category: category,
                    brand: brand,
                    model: 'default',
                    capacity: null,
                    rid: 'form',
                    channel: channel,
                    service_type: "New Request",
                    isFree: 4, //quotation
                    price: 0.00,
                    currency: "euro",
                    area: null
                }
            });
            uid = init.data.uid;
        }
        // console.log(data); 
        // console.log(init); 
        console.log(uid);  
        const quote = await Axios.get('/api/quote/' + uid);
        // console.log(quote.data); 
        this.setState({ loaded: true, quote: quote.data });
    }

    render() {

        const { t } = this.props;

        return (
            <>
                <div className="container survey-page">
                    <div className="row mt-4 mb-1">
                        <div className="col-12">
                            <h1>Booking</h1>
                        </div>
                        <div className="col-12">
                            <h6>Help us select the best service professional by answering a few more questions</h6>
                        </div>
                    </div>
                    <div className="row mt-1 mb-5 ty-breadcrumb">
                        <div className="col-12 p-0">
                            <div className="d-flex justify-content-start align-items-center">
                                <div className="mr-3">
                                    {this.state.quote.category}
                                </div>
                                /
                                <div className="mr-3 ml-3">
                                    {this.state.quote.brand}
                                </div>
                                /
                                <div className="mr-3 ml-3">
                                    {this.state.quote.model}
                                </div>
                                /
                                <div className="mr-3 ml-3">
                                    {this.state.quote.service}
                                </div>
                                /
                                <div className="mr-3 ml-3">
                                    Booking #{this.state.quote.uid}
                                </div>
                                <div className="mr-3 ml-3 f-r-q desktop">
                                {t('Not what you are looking for')} <a href="/">{t('Search Again')}</a>
                                </div>
                            </div>
                            <div className="mr-3 ml-3 f-r-q mobile">
                                {t('Not what you are looking for')} <a href="/">{t('Search Again')}</a>
                                </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-5 bordered-body-grey">
                        <iframe 
                            id="surveyIframe" 
                            title="survey" 
                            src={this.state.quote.survey_url} 
                            align="center" 
                            style={{ margin: '0 auto', border: '0', width: '100%', height: '70vh' }}
                        />
                    </div>
                    <Helmet>
                        <script defer src={withPrefix('iframeResizer.min.js')} type="text/javascript" />
                        <script defer src={withPrefix('custom.js')} type="text/javascript" />
                    </Helmet>
                </div>
            </>
        )
    }
}

export default Request;
